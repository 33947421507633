.welcome{
    font-family: 'Muli', sans-serif;
    font-size: 42px;
    color: #061c3a;
    margin: 50px 0px 50px 0px;
}

.intro{
    font-family: 'Muli', sans-serif;
    padding-left: 20%;
    font-size: 1.5rem;
    color: #061c3a;
    max-width: 80%;
}

video{
    padding-top: 100px;
    position: absolute;
    max-width: 100%;
    z-index: 1;
    top: auto;
    left: 30px;
}

.goldlogo{
    padding-top: 200px;
    position: absolute;
    z-index: 2;
    top: auto;
    left: 200px;
}