html{
    box-sizing: border-box;
    background-color: #F3F3F3;
}

*, *::before, *::after{
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}
.app{
    width: 1600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}