.header {
	background-color: #061C3A;
	padding-top: .5rem;
}

nav ul {
	list-style: none;
	display: flex;
	margin-bottom: .5rem;
	justify-content: center;
}

nav ul li {
	font-family: 'Muli', sans-serif;
	padding-inline-start: 2rem;
	padding-inline-end: 2rem;
	padding-top: .5rem;
	padding-bottom: .5rem;
	font-size: 1.3rem;
}

nav ul li a {
	color: #E7C470;
	text-decoration: none;
}

.active {
	border-bottom: 4px solid Gold;
}

.inv-logo {
	width: 100px;
}

.main-title{
	color: #FFFFFF;
	font-size: 2.5rem;
	font-family: 'Muli', sans-serif;
}

.title-box {
	display: flex;
	justify-content: center;
	align-items:center;
 }