.sctitle2{
	color: #14315C;
	font-family: 'Muli', sans-serif;
}

.ctrl-chkbx{
	padding-bottom: 20px;
}

.ctrl-chkbx input[type=checkbox] + label {
	display: block;
	margin: 0.2em;
	cursor: pointer;
	padding: 0.2em;
}

.ctrl-chkbx input[type=checkbox] {
	display: none;
}

.ctrl-chkbx input[type=checkbox] + label:before {
	content: "\2714";
	border: 0.1em solid #000;
	border-radius: 0.2em;
	display: inline-block;
	width: 1.3em;
	height: 1.2em;
	padding-left: auto;
	padding-bottom: 1.2em;
	margin-right: 0.2em;
	color: transparent;
	transition: .2s;
}

.ctrl-chkbx input[type=checkbox] + label:active:before {
	transform: scale(0);
}

.ctrl-chkbx input[type=checkbox]:checked + label:before {
	color: white;
	background-color: #14315C;
	border-color: #14315C;
}

.ctrl-chkbx input[type=checkbox]:disabled + label:before {
	transform: scale(1);
	border-color: #aaa;
}

.ctrl-chkbx input[type=checkbox]:checked:disabled + label:before {
	transform: scale(1);
	color: #aaa;
}
.sc-td1, .sc-td2, .sc-td3, .sc-td4, .sc-td6, .sc-td7{
	font-family: 'Muli', sans-serif;
	color: #14315C;
	font-size: 1.6rem;
	padding-left: 80px;
	padding-right: 80px;
	padding-top: 30px;
	padding-bottom: 0px;
	vertical-align: top;
}
.sc-td5{
	font-family: 'Muli', sans-serif;
	color: #14315C;
	vertical-align: top;
}
.cbx-sci-div{
	font-size: 1.6rem;
	padding-left: 80px;
	padding-right: 80px;
	padding-top: 30px;
	padding-bottom: 0px;
}
.cbx-sci-int-div{
	padding-top: 3px;
}

.cbx-table-03{
	border: 7px solid #14315C;
	margin: auto;
}

.sc-data{
	font-family: 'Muli', sans-serif;
	color: #14315C;
}

.-pagination {
	color: #d6b46b;
}

.tab{
	font-family: 'Muli', sans-serif;
	font-size: 1.3rem;
}

.selection-drop{
	font-family: 'Muli', sans-serif;
	height: 32px;
	width: 65px;
	color: white;
	margin: 10px 0 0 0;
	background-color: #555;
	border-color: white;
	border-width: 3px;
	border-radius: 0.5rem;
	font-size: 1.0rem;
	padding-top: 3px;
}

.cbx-sci-int-div{
	display: inline-flex;
}

.submit-btn{
    font-family: 'Muli', sans-serif;
    height: 25px;
    width: 100px;
    color: #E7C470;
    margin: 50px 0 50px 30px;
    background-color: #14315C;
    border-color: #14315C;
    border-style: solid;
    border-width: 3px;
    border-radius: 200px;
    font-size: 1.4rem;
    padding-bottom: 30px;
}