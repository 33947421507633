.inv-logo{
    margin: 0px 0px 0px 300px;
}
#login, #logout {
    font-family: 'Muli', sans-serif;
    font-size: 1.4rem;
    color: #E7C470;
    margin: 0px 0px 0px 300px;
    background-color: #14315C;
    border-color: white;
    border-style: solid;
    border-width: 3px;
    border-radius: 25px;
    padding: 10px;
}